import Velocity from 'velocity-animate'

const duration = 300
const easing = 'easeInOutExpo'
/**
 * @param element Element, callback object
 */
const VelocitySlideToggle = (element, callback) => {
  const animation = element.style.display === 'none' ? 'slideDown' : 'slideUp'
  Velocity(element, animation, {
    duration,
    easing,
    ...callback
  })
}
/**
 * @param element Element, classNames String
 */
const toggleClass = ({
  className, classList
}, classNames) => {
  classNames = classNames.split(' ')
  for (const _class of classNames) {
    const action = className.includes(_class) ? 'remove' : 'add'
    classList[action](_class)
  }
};

// le menu en cours ouvert remonte au clic sur un autre menu
(function (w, d, undefined) {
  d.addEventListener('DOMContentLoaded', () => {
    const toggler = d.querySelectorAll('.child-toggler')
    function handleClick(e) {
      const _this = this
      Velocity(d.querySelectorAll('.list-child-menu'), 'slideUp', {
        duration,
        easing
      })
      VelocitySlideToggle(e.target.nextElementSibling, {
        begin() {
          toggleClass(_this, 'fa-angle-down fa-angle-up')
        },
        complete() {
          toggler.forEach((_toggler) => {
            if (_toggler.nextElementSibling.style.display === 'none') {
              _toggler.classList.remove('fa-angle-up')
              _toggler.classList.add('fa-angle-down')
            }
          })
        }
      })
    }

    toggler.forEach((_toggler) => _toggler.addEventListener('click', handleClick))
  })
}(window, document))

