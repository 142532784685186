import {
  __rem
} from './../config';

(function ($) {
  $('.slick-carousel-sous-page-style-1 > div > div').slick({
    slidesToShow: 3,
    responsive: [
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          centerMode: true,
          centerPadding: __rem(10)
        }
      }
    ]
  })

  const slider = $('.slick-carousel-sous-page-style-2 > div > div')
  const slides_count = slider.children().length
  const slidesPerRow = 3
  const rows = Math.ceil(slides_count / slidesPerRow)
  const options = {
    slidesPerRow,
    rows,
    responsive: [
      {
        breakpoint: 767,
        settings: {
          slidesPerRow: 1,
          centerMode: true,
          centerPadding: __rem(10),
          rows: 1
        }
      }
    ]
  }

  slider.slick(options)
}($))
