(function ($, w, d, undefined) {
  if ($.isFunction($.fn.stacktable)) {
    $('.accommodations-table').stacktable()

    // add tooltips on desktop icons
    setTimeout(() => {
      $('.accommodations-table.large-only [data-toggle="tooltip"]').tooltip()
    }, 100)
  }
}($, window, document))
