document.addEventListener('DOMContentLoaded', () => {
  document.addEventListener('rsq.service.generic.content.setContainer', (containerEvent) => {
    // trigger YouTube video parsing when opening modal for the first time
    const videoLinkEl = document.querySelector(`[href="${containerEvent.detail.url}"]`)
    if (videoLinkEl && !videoLinkEl.hasAttribute('data-video-loaded')) {
      const targetId = videoLinkEl.getAttribute('data-lity-target')
      if (targetId) {
        const targetEl = document.getElementById(targetId.replace('#', ''))
        if (targetEl) {
          const rousquilleEmbeddedEl = targetEl.querySelector('.rousquille-embedded.youtube')
          if (!rousquilleEmbeddedEl) {
            // set container for our video
            containerEvent.detail.setContainer(targetEl.querySelector('.video-container'))
            videoLinkEl.setAttribute('data-video-loaded', 'true')
          }
        }
      }
    }
  })

  $(document).on('lity:open', (event, instance) => {
    // add autoplay on youtube vids
    const opener = instance.opener()
    const hash = opener.data('lity-target')
    const yt_src = opener.attr('href')
    const iframeEl = $(`${hash} .rousquille-embedded.youtube > iframe`)
    if (iframeEl.length) {
      iframeEl.attr('src', yt_src)
    }

    // add GA event when clicking on videos
    if (typeof Nematis !== 'undefined' && typeof Nematis.forms2GA !== 'undefined' && typeof Nematis.forms2GA !== 'undefined') {
      if (typeof opener.data('ga-context') !== 'undefined') {
        Nematis.forms2GA.triggerGA('Clic - Vidéo', yt_src, opener.data('ga-context'))
      }
    }
  })

  $(document).on('lity:remove', (event, instance) => {
    // remove autoplay on youtube vids
    const hash = instance.opener().data('lity-target')
    const iframeEl = $(`${hash} .rousquille-embedded.youtube > iframe`)
    if (iframeEl.length) {
      const yt_src = iframeEl.attr('src').replace('&autoplay=1', '')
      iframeEl.attr('src', yt_src)
    }
  })
})
