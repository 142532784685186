(function (w, d, undefined) {
  const layer = d.querySelector('.bandeau-layer')
  if (layer) {
    if (layer.querySelectorAll(':not(div)').length) {
      const star = '<i class="fas fa-star star"></i>'
      let content = `<div class="five-stars text-center">${star.repeat(5)}</div>`
      content += layer.innerHTML
      layer.innerHTML = content

      const textPosition = () => {
        if (!layer.parentNode.classList.contains('bandeau-article')) {
          if (w.innerHeight <= 767) {
            layer.classList.add('adjust')
          } else {
            layer.classList.remove('adjust')
          }
        }
      }

      textPosition()

      w.addEventListener('resize', textPosition)
    } else {
      layer.style.display = 'none'
      layer.classList.remove('d-lg-block')
    }
  }
}(window, document))
