import {
  __rem, dateForceWeek, datepickerOptions as options
} from './../config'
import moment from 'moment';

(function ($, w, d, undefined) {
  const resaFormPosition = () => {
    const resaForm = d.getElementById('resa-form-sticky')
    if (resaForm) {
      const formBox = resaForm.querySelector('.resa-form-box')
      formBox.classList.remove('on-bottom')
      const bottomOfWindow = w.pageYOffset + w.innerHeight
      const bottomOfBox = resaForm.offsetTop + resaForm.offsetHeight

      if (bottomOfWindow >= bottomOfBox) {
        formBox.classList.add('on-bottom')
      }

      const onBottom = formBox.classList.contains('on-bottom')

      const scroll = d.querySelector('.scroll')
      if (scroll) {
        scroll.classList.add('on-bottom')
      }

      const backToTop = d.querySelector('.back-to-top')
      if (backToTop) {
        if (onBottom) {
          backToTop.classList.add('on-bottom')
        } else {
          backToTop.classList.remove('on-bottom')
        }
      }

      const backwardsLink = d.querySelector('.backwards')
      if (backwardsLink) {
        if (onBottom) {
          backwardsLink.classList.add('on-bottom')
        } else {
          backwardsLink.classList.remove('on-bottom')
        }
      }

      // hauteur de l'image à la une
      const bandeau = d.querySelector('.bandeau')
      if (bandeau) {
        bandeau.style.height = __rem(w.innerHeight)

        if (w.innerWidth < 768) {
          bandeau.style.height = __rem(500)
        }
      }
    }
  }
  resaFormPosition()
  const init = () => resaFormPosition()
  w.addEventListener('resize', init)
  w.addEventListener('scroll', () => w.requestAnimationFrame(init))

  // jQuery datepicker
  $('.date').attr('autocomplete', 'off')
  $(':not(.resa-form-sticky) .input-daterange').datepicker(options)
  $('.resa-form-sticky .input-daterange').datepicker({
    ...options,
    container: '.resa-form-sticky form'
  })

  $('.input-daterange .date-arrivee').datepicker()
    .on('changeDate', function (e) {
      const startDate = moment(e.format(), 'DD/MM/YYYY')
      const minEndDate = moment(e.format(), 'DD/MM/YYYY').add(1, 'd')
      const inputTo = $(this).parents('.input-daterange').find('.date-depart')

      // au chargement et si on est en haute saison (config.js) on part sur du 7 nuits
      if (inputTo.val() == $(this).val() && startDate.isBetween(dateForceWeek.start, dateForceWeek.end, 'day', '[)')) {
        inputTo.datepicker('setDate', moment(e.format(), 'DD/MM/YYYY').add(7, 'd').format('DD/MM/YYYY'))
      }

      const myEndDate = moment(inputTo.val(), 'DD/MM/YYYY')
      if (myEndDate.isValid() && myEndDate < minEndDate) {
        inputTo.datepicker('setDate', minEndDate.format('DD/MM/YYYY'))
      }

      inputTo.datepicker('setStartDate', minEndDate.format('DD/MM/YYYY')).datepicker('show')
    })

  // Au scroll on cache le calendrier
  // Si on est en version Desktop uniquement ! (bug iOS)
  if (w.outerWidth > 1199) {
    w.addEventListener('scroll', () => $('form:not(.mobile) .input-daterange .date').blur().datepicker('hide'))
  }

  $('form.resalys').submit(function () {
    calculate_duration(this)
    // return false
  })

  function calculate_duration(form) {
    const from = moment($(form).find('.date-arrivee').val(), 'DD/MM/YYYY')
    const to = moment($(form).find('.date-depart').val(), 'DD/MM/YYYY')

    const day = to.diff(from, 'days')
    $(form).find('input.duration').val(day)
  }
}($, window, document))
