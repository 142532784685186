import {
  __rem
} from './../config';

(function ($, undefined) {
  if (!(navigator.userAgent.match(/Android/i) && navigator.userAgent.match(/Chrome/i))) {
    $('.hebergement-others').slick({
      arrows: false,
      slidesToShow: 3,
      responsive: [{
        breakpoint: 991,
        settings: {
          slidesToShow: 1,
          centerMode: true,
          centerPadding: __rem(15)
        }
      }]
    })
  }
}($))

// debug
// add a listener to 'scroll' event
// document.addEventListener("scroll", function() {
// get the active element and call blur
// console.log('scroll...')
// document.activeElement.blur()
// });

// $('body,html,window').on('scroll', function() {
// $(':focus').blur();
// console.log('JQ scroll...')
// });
