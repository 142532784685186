
(function ($, d) {
  const backwardsLink = d.querySelector('#backwards')
  if (backwardsLink) {
    backwardsLink.addEventListener('click', (event) => {
      event.preventDefault()
      window.history.back()
    })
  }
}($, document))
