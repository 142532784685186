import animateScrollTo from 'animated-scroll-to'

(function ($, w, d, undefined) {
  const anchors = d.querySelectorAll('.anchor-link')
  const stickyEl = d.querySelector('#sticky, .sticky-top, .header-sticky')

  anchors.forEach((anchor) => {
    anchor.addEventListener('click', function (event) {
      event.preventDefault()
      const href = this.getAttribute('href')
      const target = d.querySelector(href)
      if (target) {
        let offset = 0
        if (stickyEl) {
          offset -= stickyEl.offsetHeight - 15
        }
        animateScrollTo(target, {
          offset
        })
      }
    })
  })

  // Load url with anchor
  const hash = window.location.hash
  if (hash) {
    const hashEl = d.querySelector(hash)

    if (hashEl) {
      let offset = 0
      if (stickyEl) {
        offset -= stickyEl.offsetHeight - 15
      }
      animateScrollTo(hashEl, {
        offset
      })
    }
  }
}($, window, document))
