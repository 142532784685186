import {
  isLoggedIn
} from './../config'
import {
  __rem
} from './../config';

(function (window, document, undefined) {
  document.addEventListener('DOMContentLoaded', () => {
    const btnCanvas = document.querySelectorAll('.btn-canvas-reserve')
    const btnNavToggler = document.querySelector('#nav-main .navbar-toggler')

    show_offcanvas(btnNavToggler);

    [].forEach.call(btnCanvas, (btn) => {
      show_offcanvas(btn)
    })
  })
}(window, document))

const show_offcanvas = (element) => {
  const navCanvas = document.querySelector(element.getAttribute('data-target'))
  const btnNavCanvasClose = document.querySelector(navCanvas.getAttribute('data-close-target'))

  element.onclick = function () {
    if (isLoggedIn) {
      navCanvas.style.top = __rem(document.getElementById('wpadminbar').offsetHeight)
    }

    navCanvas.classList.toggle('show')
    document.body.classList.add('no_scroll')
  }

  btnNavCanvasClose.onclick = function () {
    navCanvas.classList.remove('show')
    document.body.classList.remove('no_scroll')
  }
}
