(function (window, document, undefined) {
  document.addEventListener('DOMContentLoaded', () => {
    const wpcf7s = document.querySelectorAll('.form-field .wpcf7-form-control')

    // Array.prototype.forEach.call(wpcf7s, function(wpcf, i){
    for (const wpcf of wpcf7s) {
      // console.log(wpcf.type)
      if (typeof wpcf.value === 'undefined' || wpcf.value == '') {
        if (wpcf.closest('.form-field').classList) {
          wpcf.closest('.form-field').classList.remove('filled')
        }
        // les selects
        if (wpcf.type == 'select-one') {
          // console.log(wpcf)
          wpcf.querySelector('option[value=""]').innerHTML = ''
        }
      } else {
        wpcf.closest('.form-field').classList.add('filled')
      }

      wpcf.addEventListener('focus', (event) => {
        wpcf.closest('.form-field').classList.add('filled')
      })

      wpcf.addEventListener('blur', (event) => {
        if (typeof wpcf.value === 'undefined' || wpcf.value == '') {
          if (wpcf.closest('.form-field').classList) {
            wpcf.closest('.form-field').classList.remove('filled')
          }
        }
      })
    }
    /*
        $('.form-field .wpcf7-form-control').each(function () {
            if (!$(this).val()) {
              $(this).parent().parent().removeClass('filled')
            } else {
              $(this).parent().parent().addClass('filled')
            }

            $(this).on('focus', function () {
              $(this).parent().parent().addClass('filled')
            })

            $(this).on('blur', function () {
              if (!$(this).val()) {
                $(this).parent().parent().removeClass('filled')
              }
            })
          })
          */
  })
}(window, document))
