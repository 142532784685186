(function (w, d, undefined) {
  const paragraphs = d.querySelectorAll('.truncate')
  paragraphs.forEach((paragraph) => {
    // Destructuration de l'objet paragraph
    const {
      innerHTML,
      innerText,
      dataset: {
        length,
        readMore,
        resolution
      }
    } = paragraph
    const content = innerHTML
    const text = innerText.trim()
    // console.log('Data-Resolution : ' + resolution)

    if (w.outerWidth > 991 && resolution != 'all') {
      return
    }

    if (text.length > length) {
      const html = `<p>${text.substr(0, length)}
				<span class="lire-plus">
					<a href="javascript:void(0)">
						${readMore} <i class="far fa-angle-down"></i>
					</a>
				</span>
			</p>`
      paragraph.innerHTML = html
      paragraph.querySelector('.lire-plus a').addEventListener('click', () => paragraph.innerHTML = content)
    }
  })
}(window, document))
