import animateScrollTo from 'animated-scroll-to';

(function (w, d) {
  const backToTopButton = d.getElementById('back-to-top') || {}
  const body = d.getElementsByTagName('body')[0]
  const topButton = d.querySelector('.back-to-top')
  const backButton = d.querySelector('.backwards')
  const toggleButton = () => {
    const wScrollTop = w.pageYOffset || body.scrollTop
    const regexp = /(d\-block)/i
    const classFound = topButton.className.match(regexp)
    const scrollValue = topButton.offsetHeight
    // console.log(wScrollTop);
    if (wScrollTop > scrollValue && !classFound) {
      topButton.classList.add('d-block')

      if (backButton) {
        backButton.classList.add('d-block')
      }
    }

    // si le scroll est inférieur à 2 et
    if (wScrollTop < 2 && classFound) {
      topButton.classList.remove('d-block')

      if (backButton) {
        backButton.classList.remove('d-block')
      }
    }
  }
  const onScrolling = () => toggleButton()
  backToTopButton.addEventListener('click', () => {
    const top = 0
    animateScrollTo(top, {
      speed: 200
    })
  }, false)

  // quand on scroll
  w.addEventListener('scroll', function () {
    // on exécute la fonction onScrolling()
    this.requestAnimationFrame(onScrolling)
  })
}(window, document))
