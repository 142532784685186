import {
  __rem
} from './../config';

(function ($, d) {
  const childPages = $('.children-pages')
  const addClass = (el, className) => {
    if (el) {
      el.classList.add(className)
    }
  }
  const addNextPrevClasses = (slider) => {
    const active = slider.querySelector('.slick-active')
    if (active) {
      addClass(active.previousSibling, 'prev')
      addClass(active.nextSibling, 'next')
    }
  }
  childPages.on({
    init() {
      addNextPrevClasses(this)
    },
    beforeChange() {
      const removeClass = (slide) => slide.classList.remove('prev', 'next')
      this.querySelectorAll('.slick-slide').forEach(removeClass)
    },
    afterChange() {
      addNextPrevClasses(this)
    }
  })
    .slick({
      arrows: false,
      slidesToShow: 3,
      responsive: [
        {
          breakpoint: 991,
          settings: {
            centerMode: true,
            centerPadding: __rem(15),
            slidesToShow: 1
          }
        }
      ]
    })
}($, document))
