// Enregistrer le nom et l'email de l'utilisateur
(function (w, d, undefined) {
  const saveUserButton = d.getElementById('save-user-and-email')

  if (saveUserButton) {
    const saveUserButtonParent = saveUserButton.parentNode
    const authorInput = d.getElementById('author')
    const emailInput = d.getElementById('email')
    const loadUser = () => {
      // après le chargement de la page
      const savedUser = JSON.parse(localStorage.getItem('saved_user'))
      if (savedUser) {
        const {
          author,
          email
        } = savedUser
        authorInput.value = author
        emailInput.value = email
        saveUserButtonParent.classList.add('remembered')
      }
    }
    const saveUser = () => {
      const author = authorInput.value
      const email = emailInput.value
      const user = {
        isRemembered: true,
        author,
        email
      }
      if (author || email) {
        localStorage.setItem('saved_user', JSON.stringify(user))
        saveUserButtonParent.classList.add('remembered')
      }
    }
    // charger l'utilisateur enregistré
    loadUser()

    // quand on clique sur le lien...
    saveUserButton.addEventListener('click', (event) => {
      const savedUser = JSON.parse(localStorage.getItem('saved_user'))
      if (savedUser) {
        // on supprime l'utilisateur s'il est déjà enregistré
        localStorage.removeItem('saved_user')
        saveUserButtonParent.classList.remove('remembered')
      } else {
        // ... ou on l'enregistre
        saveUser()
      }
      event.preventDefault()
    })

    const commentForm = d.getElementById('comment-form')
    const handleChange = ({
      target: {
        name
      }
    }) => {
      if (['author', 'email'].includes(name) && localStorage.getItem('saved_user')) {
        saveUser()
      }
    }
    if (commentForm) {
      commentForm.querySelectorAll('[name]').forEach((field) => field.addEventListener('change', handleChange))
    }
  }
}(window, document))
