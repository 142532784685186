import videoBackground from 'vidim'
import {
  __rem
} from './../config'

(function ($, w, d, undefined) {
  const userPosts = () => {
    const posts = d.querySelector('.user-posts')
    if (posts) {
      const items = posts.querySelectorAll('.item')
      const setHeight = () => items.forEach((current) =>
        current.style.height = __rem(current.offsetWidth)
      )

      setHeight()
      w.addEventListener('resize', setHeight)
    }
  }

  userPosts()

  const background = () => {
    const youtube = d.getElementById('youtube-container')
    if (youtube) {
      const {
        dataset: {
          src,
          poster,
          params
        }
      } = youtube
      const attrs = JSON.parse(params)
      console.log(attrs)
      const video = new videoBackground('#youtube-container', {
        autoplay: attrs.autoplay || false,
        type: 'YouTube',
        src,
        poster
      })
      const playButton = d.getElementById('play-full')
      playButton.addEventListener('click', function (event) {
        video.play()
        this.classList.add('started')
        event.preventDefault()
        setTimeout(() => playButton.style.zIndex = '-1', 400)
      })
      if (attrs.autoplay) {
        playButton.style.zIndex = '-1'
      }
    }
  }

  background()

  // Mettre des blocs en full width
  const fullWidth = () => {
    const elements = d.querySelectorAll('.full-width-mobile')
    const handle = (current) => {
      const makeFullWidth = (element) => {
        const windowWidth = w.innerWidth
        const currentWidth = element.offsetWidth
        const left = (currentWidth - windowWidth) / 2
        if (windowWidth < 768) {
          element.style.width = __rem(windowWidth)
          element.style.left = __rem(left)
          element.style.position = 'relative'
        } else {
          element.style.width = 'initial'
          element.style.left = 0
        }
      }
      const onResize = () => {
        current.style.width = 'initial'
        makeFullWidth(current)
      }
      makeFullWidth(current)

      w.addEventListener('resize', onResize)
    }
    elements.forEach(handle)
  }

  fullWidth()

  $('.slick-carousel > div > div').slick({
    arrows: false,
    slidesToShow: 3,
    responsive: [
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 1,
          centerMode: true,
          centerPadding: __rem(15)
        }
      }
    ]
  })

  $('.ressourcer').slick({
    dots: true,
    mobileFirst: true,
    slidesToShow: 1,
    responsive: [
      {
        breakpoint: 767,
        settings: 'unslick'
      }
    ]
  })

  $('.home .hebergement-carousel').slick({
    arrows: false,
    slidesToShow: 4,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          centerMode: true,
          centerPadding: __rem(15)
        }
      }
    ]
  })

  if (!(navigator.userAgent.match(/Android/i) && navigator.userAgent.match(/Chrome/i))) {
    $('body:not(.home) .hebergement-carousel').slick({
      arrows: false,
      slidesToShow: 4,
      responsive: [
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 2,
            centerMode: true,
            centerPadding: __rem(15)
          }
        }
      ]
    })
  }
}($, window, document))
