(function ($, undefined) {
  const boxes = document.querySelectorAll('.box-link')

  boxes.forEach((boxe) => {
    boxe.addEventListener('click', (event) => {
      event.preventDefault()

      const a = boxe.querySelector('a[href]')
      let target = '_self'

      console.log('BOX LINK', a, a.getAttribute('rel'))

      if (typeof a.getAttribute('rel') !== typeof undefined && a.getAttribute('rel') !== null && a.getAttribute('rel').indexOf('external') > -1 || event.ctrlKey) {
        target = '_blank'
      }

      window.open(a.getAttribute('href'), target)
    })
  })
}($))
