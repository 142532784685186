(function ($, undefined) {
  $('.wp-block-gallery').lightGallery({
    selector: '.blocks-gallery-item figure a',
    thumbnail: true,
    closable: true,
    download: false,
    getCaptionFromTitleOrAlt: true,
    fullscreen: true
  })
  $('.wp-block-image').lightGallery({
    selector: 'figure a',
    thumbnail: true,
    closable: true,
    download: false,
    getCaptionFromTitleOrAlt: true,
    fullscreen: true
  })
}($))
