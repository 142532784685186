import {
  __rem, datepickerOptions
} from './../config';

// Page détail hébergement
// Nos services
(function ($, w, d, undefined) {
  const allServices = d.getElementById('all-services')
  if (!allServices) {
    return
  }
  // Select all <li> elements
  const services = allServices.querySelectorAll('li')
  const makeNewElements = (nodeList, count, newElements = '') => {
    const _services = Array.from(nodeList).slice(0, count)
    _services.forEach((service) => newElements += service.outerHTML)
    return newElements
  }
  const showMoreServices = d.querySelector('.show-more-services')
  const showMoreServicesLink = d.getElementById('show-more-services')
  const SERVICE_TO_SHOW_DESKTOP = 5
  const SERVICE_TO_SHOW_MOBILE = 6000
  const displayServices = () => {
    if (showMoreServices.style.display === 'none') {
      return
    }
    let servicesToShow = SERVICE_TO_SHOW_MOBILE
    if (w.outerWidth > 991) {
      servicesToShow = SERVICE_TO_SHOW_DESKTOP
    }

    if (servicesToShow >= services.length) {
      showMoreServices.style.display = 'none'
    }

    const newElements = makeNewElements(services, servicesToShow)
    allServices.innerHTML = newElements
  }
  const onResize = () => displayServices()
  displayServices()
  w.addEventListener('resize', onResize)

  // on affiche les autres services quand on clique sur Afficher les 17 services
  // uniquement en mobile : en desktop c'est une modale
  if (w.outerWidth < 992) {
    showMoreServicesLink.addEventListener('click', (event) => {
      showMoreServices.style.display = 'none'
      allServices.innerHTML = makeNewElements(services, services.length)
      event.preventDefault()
    })
  }

  // jQuery datepicker
  $('.dates.input-daterange').datepicker({
    ...datepickerOptions,
    container: '.dates-du-sejour .dates'
  })

  // Media
  $('.media-carousel').slick({
    // arrows: true,
    infinite: false,
    slidesToShow: 4,
    slidesToScroll: 4,
    // prevArrow: $('#prev-media'),
    // nextArrow: $('#next-media'),
    centerMode: false,
    centerPadding: __rem(15),
    responsive: [
      {
        breakpoint: 1199,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2
        }
      }
    ]
  })

  const $mc = $('.media-carousel')
  $mc.lightGallery({
    selector: '.media-item .image-thumbnail',
    thumbnail: true,
    closable: true,
    download: false,
    fullscreen: true
  })
  const openCarouselEl = d.querySelector('.open-carousel')
  if (openCarouselEl) {
    openCarouselEl.addEventListener('click', (event) => {
      // open gallery on header btn click
      $mc.find('.media-item .image a img')[0].click()
      event.preventDefault()

      // add GA event when clicking on "See the pictures"
      if (typeof Nematis !== 'undefined' && typeof Nematis.forms2GA !== 'undefined' && typeof Nematis.forms2GA !== 'undefined') {
        const accommodationName = $('.hebergement-sidebar .form-title')
        if (accommodationName.length) {
          Nematis.forms2GA.triggerGA('Clic - Voir les photos', accommodationName.text(), Nematis.forms2GA.langCode ? Nematis.forms2GA.langCode : 'Unknown')
        }
      }
    }, false)
  }


  // Tarifs Tab
  /*
    const tarifNavButton = d.querySelector('.nav-tarif-right'),
    tarifsContent = d.querySelector('.tarifs-content');
    let tarifLeft = 1, tarifRight = 0;

    tarifNavButton.addEventListener("click",() => {
        let tarifColumn = d.querySelector('#nav-tabContent .active .tarifs').children;
        tarifRight = tarifColumn.length - tarifLeft;
        const widthColumn = tarifColumn[0].offsetWidth;

        if (tarifRight > 1) {
            tarifsContent.style.marginLeft = `-${__rem(widthColumn * tarifLeft)}`;
            tarifLeft++;
        } else if (tarifRight == 1) {
            tarifsContent.style.marginLeft = 0;
            tarifLeft = 1;
        }
    });
    */
}($, window, document))


