const $ = require('jquery')

import './plugins'
import './common'
// import '../images'
import './core'
import './templates'
import './accommodations'
import './posts'
// import './SmoothScroll'
// import 'lazysizes'
