import 'theia-sticky-sidebar'
import {
  isLoggedIn
} from './../config';

(function ($, d) {
  const sidebar = d.querySelector('.layout-sidebar')
  if (sidebar) {
    let additionalMarginTop = 30
    const adminBar = d.getElementById('wpadminbar')
    if (isLoggedIn && adminBar) {
      additionalMarginTop += adminBar.offsetHeight
    }
    $('.content-wrapper-master, .layout-sidebar').theiaStickySidebar({
      additionalMarginTop,
      minWidth: 991
    })
  }
}($, document))
