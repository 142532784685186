import {
  isLoggedIn
} from './../config'
import animateScrollTo from 'animated-scroll-to'
import {
  __rem
} from './../config';

// == Sticky menu en Vanilla : https://www.creativejuiz.fr/blog/tutoriels/creer-menu-sticky-avec-javascript-css
(function (w, d, undefined) {
  const body = d.getElementsByTagName('body')[0]
  const header = d.getElementById('header')
  const mobileWidth = 991
  const stickHeader = function () {
    if (window.outerWidth > mobileWidth) {
      header.classList.remove('header-sticky')
      header.style.marginTop = 0
      return
    }

    const wScrollTop = w.pageYOffset || body.scrollTop
    const regexp = /(header\-sticky)/i
    const classFound = header.className.match(regexp)
    const scrollValue = header.offsetHeight
    // console.log(wScrollTop);
    if (wScrollTop > scrollValue && !classFound) {
      header.classList.add('header-sticky')
    }

    // si le scroll est inférieur à 2 et
    if (wScrollTop < 2 && classFound) {
      header.classList.remove('header-sticky')
    }
  }
  const onScrolling = function () {
    stickHeader()
  }
  const onConnectedHeader = () => {
    if (isLoggedIn) {
      const {
        innerWidth
      } = w
      const adminBar = d.getElementById('wpadminBar')
      let height = 0
      if (adminBar) {
        height = __rem(adminBar.offsetHeight)
      }
      if (innerWidth < 601) {
        height = 0
      }
      header.style.marginTop = height
    }
  }
  const setHeaderSize = () => {
    const contentHeader = d.querySelector('.content-header-fullheight#content-header')
    if (contentHeader) {
      contentHeader.style.height = __rem(w.innerHeight)
    }
  }
  const onResize = () => {
    onConnectedHeader()
    setHeaderSize()
  }

  onConnectedHeader()
  setHeaderSize()

  // quand on scroll
  w.addEventListener('scroll', () => {
    // on exécute la fonction onScrolling()
    w.requestAnimationFrame(onScrolling)
  })

  // on exécute la fonction onResize quand on redimensionne l'écran
  w.addEventListener('resize', onResize)

  const scrollToSearch = (event) => {
    let target = d.getElementById('resa-form-sticky')
    if (target) {
      target = target.offsetTop
      // if (isLoggedIn) {
      //     target -= d.getElementById('wpadminbar').offsetHeight;
      // }
      animateScrollTo(target)
    } else {
      localStorage.setItem('__scroll_search', true)
      w.location = w.location.origin
    }

    event.preventDefault()
  }

  if (localStorage.getItem('__scroll_search')) {
    w.location.hash = 'resa-form-sticky'
    localStorage.removeItem('__scroll_search')
  }

  const scroll = d.getElementById('scroll')
  const loupe = d.getElementById('scroll-to-search')
  if (scroll) {
    scroll.addEventListener('click', () => animateScrollTo(w.innerHeight))
  }
  if (loupe) {
    loupe.addEventListener('click', scrollToSearch)
  }
}(window, document));

(function (w, d) {
  const itemTel = d.querySelector('.item-tel')
  const show = () => {
    if (w.innerWidth > 991) {
      itemTel.classList.add('hover')
    }
  }
  const hide = () => itemTel.classList.remove('hover')

  itemTel.addEventListener('mouseover', show)
  itemTel.addEventListener('mouseleave', hide)
  itemTel.querySelector('.show-coordonnees').addEventListener('click', (event) => {
    if (w.innerWidth > 991) {
      itemTel.classList.toggle('open')
      event.preventDefault()
    }
  })
}(window, document))
